import React, { useEffect } from "react";
// import { ConfigProvider } from "react-avatar";
// import LoadingBar from "react-top-loading-bar";
import "rsuite/dist/rsuite.min.css";
import { useStateIfMounted } from "use-state-if-mounted";
import "./assets/scss/components.scss";
import "./assets/scss/main.scss";
import "./assets/scss/full_calendar.scss";
// import color from "./constants/colors";
// import EspecialistasNavigation from "./navigation/especialistas";
// import PacientesNavigation from "./navigation/pacientes";
import WebNavigation from "./navigation/web";
// import EspecialistasAPIClass from "./services/especialistas";
// import PacientesAPIClass from "./services/pacientes";
import { UserProvider } from "./state/UserState";
import { CalendarProvider } from "./state/CalendarState";
import { CustomProvider } from "rsuite";
// import LocaleProvider from "rsuite/lib/IntlProvider";
// import es_ES from "rsuite/lib/IntlProvider/locales/es_ES";
// import { ErrorBoundary } from "react-error-boundary";
// import ErrorPage from "./components/ErrorPage";
// import SideBar from "./components/SideBar";
// import { messaging } from "./constants/firebase";
// import { isSafari } from "react-device-detect";
import esES from 'rsuite/locales/es_ES';

// const EspecialistasAPI = new EspecialistasAPIClass();
// const PacientesAPI = new PacientesAPIClass();

const MainApp = (props) => {
  // const { userPrincipal, setUser, setUserPrincipal, setOrganizacion } =
  //   useContext(UserState);
  const [loader, setLoader] = useStateIfMounted(true);
  // const ref = useRef(null);

  // useEffect(() => {
  //   if (!isSafari && userPrincipal !== false) {
  //     _notifications();
  //   }
  // }, [userPrincipal]);

  // const _notifications = () => {
  //   // Get registration token. Initially this makes a network call, once retrieved
  //   // subsequent calls to getToken will return from cache.
  //   messaging
  //     .getToken({
  //       vapidKey:
  //         "BO2w94oJ30jtIaBQXiP2mPDNlcC497zSSFG3DvfBXiyGIa0jReFhB074Xlk1S9L7poTEK2EbIJALNEEASS6k3ig",
  //     })
  //     .then(async (currentToken) => {
  //       if (currentToken) {
  //         // Send the token to your server and update the UI if necessary
  //         // ...
  //         // console.log("====== TOKEN FIREBASE NOTIFICATIONS =====");
  //         // console.log(currentToken);

  //         let request = {
  //           token: currentToken,
  //           tipo_device: "navegador",
  //           id_perfil: userPrincipal.id,
  //           tipo_usuario: "doctor",
  //           id_organizacion: userPrincipal.organizacion.id,
  //         };
  //         const { data } = await EspecialistasAPI.SaveToken(request);
  //       } else {
  //         // Show permission request UI
  //         // console.log(
  //         //   "No registration token available. Request permission to generate one."
  //         // );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });
  // };

  useEffect(() => {
    if (localStorage.getItem("uid_user")) {
      if (localStorage.getItem("tipo_user") === "especialista") {
        window.location.href = '/especialista';
        // _getUserLogged();
      } else if (localStorage.getItem("tipo_user") === "paciente") {
        window.location.href = '/paciente';
        // _getPacienteLogged();
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  }, []);

  // const _getUserLogged = async () => {
  //   setLoader(true);

  //   const { data } = await EspecialistasAPI.GetEspecialistaByUID(
  //     localStorage.getItem("uid_user")
  //   );
  //   try {
  //     setUser(data.doctor);
  //     setUserPrincipal(data.doctor);
  //     setOrganizacion(data.doctor.organizacion);
  //     //props.history("/");
  //   } catch (error) {
  //     // console.log(error);
  //   }

  //   setLoader(false);
  // };

  // const _getPacienteLogged = async () => {
  //   setLoader(true);
  //   const { data } = await PacientesAPI.GetPacienteByUID(
  //     localStorage.getItem("uid_user")
  //   );
  //   try {
  //     setUser(data.data);
  //     setUserPrincipal(data.data);
  //     //props.history("/");
  //   } catch (error) {
  //     // console.log(error);
  //   }

  //   setLoader(false);
  // };

  return (
    <div className="app">
      {loader ? (
        <div>
          <div className="tl-loader">
            <i className="tl-loader-bar tl-loader-bar-1"></i>
            <i className="tl-loader-bar tl-loader-bar-2"></i>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <WebNavigation navigation={props}></WebNavigation>
          </div>
        </div>
      )}
    </div>
  );
};

const BaseApp = (props) => {
  // return (
  //   <ErrorBoundary FallbackComponent={ErrorPage}>
  //     <CustomProvider>
  //       <UserProvider>
  //         <CalendarProvider>
  //           <AppMain></AppMain>
  //         </CalendarProvider>
  //       </UserProvider>
  //     </CustomProvider>
  //   </ErrorBoundary>
  // );

  return (
    <CustomProvider locale={esES}>
      <UserProvider>
        <CalendarProvider>
          <MainApp></MainApp>
        </CalendarProvider>
      </UserProvider>
    </CustomProvider>
  );
};

function AppMain() {
  return <BaseApp></BaseApp>;
}

export default AppMain;
